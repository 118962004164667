/* AVUSTUSLOMAKE LAYOUT */
.avustuslomake-sivu .footer,
.avustuslomake-sivu {
	background: #3f84c5;
	padding: 0;
}
.avustuslomake-sivu .main {
	background: #FFF;
	padding-top: 32px;
	padding-bottom: 32px;
	border: 12px dashed #DDD;
    border-width: 12px 0;
    box-shadow: 0 2px 20px rgba(0,0,0,0.25);
}
.avustuslomake-sivu hr {
	margin-top: 30px;
	border-top: 1px dashed #CCC;
}
.avustuslomake-sivu hr.solid {
	border-top-style: solid;
}
.avustuslomake-sivu .bg-gray {
	background: #EEE;
	border-bottom: 1px solid #FFF;
}
.avustuslomake-sivu .lomake {
	padding: 0 10px;
}

/* HEADER */
.avustuslomake-sivu .header {
	padding-top: 20px;
}
.avustuslomake-sivu .subheader h1,
.avustuslomake-sivu .header h1 {
	margin-top: 15px;
    border: 1px solid #CCC;
   	background: #EEE;
    padding: 15px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
    color: #555;
}
.avustuslomake-sivu .header .logo {
	display: inline-block;
	max-width: 50%;
	margin-right: 30px;
	margin-bottom: 30px;
	vertical-align: top;
}
.avustuslomake-sivu .header .yhteystiedot {
	display: inline-block;
}
.avustuslomake-sivu .header .logout {
	position: absolute;
	right: 10px;
	top: -42px;
}
.avustuslomake-sivu .nav-pills {
	float: right;
}
.avustuslomake-sivu .nav-pills .logout {
	position: static;
	right: auto;
	top: auto;
}

/* COMMON FORM STYLES */
.avustuslomake-sivu .lomake-info {
	padding: 5px 16px;
	color: #666;
}
.avustuslomake-sivu .lomake-info h2 {
	font-size: 20px;
	margin-top: 10px;
}
.avustuslomake-sivu .form-group.collapsed {
	margin-bottom: 0;
}
.avustuslomake-sivu .form-group.collapsed > div {
	padding-top: 8px;
	padding-bottom: 8px;
}
.avustuslomake-sivu .form-group.collapsed > label {
	padding-top: 15px;
}
.avustuslomake-sivu .form-group p.col-help {
	margin: 0;
}
.avustuslomake-sivu .col-cut {
	padding: 15px 0;
    border-top: 1px dashed #CCC;
    margin: 15px 0px;
    border-bottom: 1px dashed #CCC;
    font-style: italic;
    color: #999;
}
.avustuslomake-sivu .lomake h3 {
	font-size: 16px;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 0;
}
.avustuslomake-sivu .lomake label {
	font-weight: normal;
}
.avustuslomake-sivu span.asteriks,
.avustuslomake-sivu .lomake label span {
	color: red;
	font-weight: bold;
}
.avustuslomake-sivu .lomake input,
.avustuslomake-sivu .lomake .input-group {
	max-width: 560px;
}
.avustuslomake-sivu .lomake .tiny {
	max-width: 75px;
}
.avustuslomake-sivu .lomake .short,
.avustuslomake-sivu .lomake .short-group  {
	max-width: 150px;
}
.avustuslomake-sivu .lomake .medium {
	max-width: 300px;
}
.avustuslomake-sivu .lomake .large {
	max-width: 620px;
}
.avustuslomake-sivu .lomake .boxed {
	padding: 10px 15px;
    margin-top: 15px;
    border: 1px solid #CCC;
    margin-bottom: 5px;
}
.avustuslomake-sivu .lomake .boxed.secondary {
	background: #EEE;
} 
.avustuslomake-sivu .lomake textarea {
	margin-top: 20px;
}
.avustuslomake-sivu .btn.laheta-nappi {
	margin-bottom: 20px;
}
.avustuslomake-sivu .btn.full {
	width: 100%;
	margin-bottom: 10px;
}

.avustuslomake-sivu .lomake .has-error .help-block.bg-danger {
	clear: both;
    padding: 5px 10px;
    font-size: 12px;
    float: left;
    width: 100%;
    margin-top: 5px;
}

.avustuslomake-sivu .drop-box {
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 0px;
    margit-right: 0px;
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
	padding-top: 0;
}
label.control-label.text-left {
	text-align: left;
}


/* FOOTER */
.avustuslomake-sivu .footer p {
	margin: 0;
	padding: 30px;
	color: #DDD;
}

.avustuslomake-sivu .footer a {
	color: #FFF;
}

/* Small devices (tablets, 768px and up) */
@media (max-width: 767px) {
	.avustuslomake-sivu label.col-cut-xs {
		padding: 15px 0 !important;
	    border-top: 1px dashed #CCC;
	    margin: 15px;
	    border-bottom: 1px dashed #CCC;
	    font-style: italic;
	    color: #999;
	}
	.avustuslomake-sivu .nav-pills {
		float: none;
		margin-top: -15px;
		margin-bottom: 15px;
	}
}


/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

	/* AVUSTUSLOMAKE LAYOUT */
	.avustuslomake-sivu .main {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.avustuslomake-sivu .lomake {
		padding: 0 15px;
	}

	.avustuslomake-sivu .lomake .boxed {
		padding: 15px 30px;
	}

	/* HEADER */
	.avustuslomake-sivu .header .logout {
		top: 0;
		right: 15px;
	}

	/* FOOTER */
	.avustuslomake-sivu .footer p {
		padding-top: 0;
	}

}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 1200px) {
	.avustuslomake-sivu .lomake {
		padding: 0;
	}
}