/* spinning glyphicon from http://chadkuehn.com/animated-font-spinners/ */

.fa-spin-custom, .glyphicon-spin {
    -webkit-animation: spin 2000ms infinite linear;
    animation: spin 2000ms infinite linear;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}


/* our additions */
.glyphicon-xxl {
	font-size: 6em;
}

.glyphicon-matri-xlarge {
	font-size: 4em;
}

.glyphicon-matri-large {
	font-size: 2em;
}

.glyphicon-matri-medium {
	font-size: 1.5em;
}
