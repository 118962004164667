.drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    width: 100%;
    height: 100px;
    text-align: center;
    padding-top: 25px;
    margin: 10px;
}
.drop-box.dragover {
    border: 5px dashed blue;
}

.drop-box.dragover-err {
    border: 5px dashed red;
}

.borderAll {

/* use border-box so border is applied inside the span and doesn't break the grid */

-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
-moz-box-sizing: border-box;    /* Firefox, other Gecko */
box-sizing: border-box;         /* Opera/IE 8+ */

/* define your border */
border-left: 1px solid black;
border-right: 1px solid black;
border-top: 1px solid black;
border-bottom: 1px solid black;
} /* borderAll */

html { background: transparent }
.container {background: white;}

.topmargin20 { margin-top:20px; }
.topmargin50 { margin-top:50px; }

#hakemuslomake {
  min-height: 200px;
  border: 1px solid #ccc;
  padding: 5px;
}

#hakemuslomake .component{
  border: 1px solid #fff;
}

/* container hallintapuolelle */
.container-hallinta {
 padding-top: 20px;
}

.gridStyle {
    border: 1px solid rgb(212,212,212);
    width: 400px;
    height: 400px !important;
}

/* 'very' condensed table for use with large data sets */
.table-very-condensed-matri {
    border-spacing: 0px;
    font-size: 10px;
    padding: 1px;
}

.table-very-condensed-matri thead > tr > th,
.table-very-condensed-matri tbody > tr > th,
.table-very-condensed-matri tfoot > tr > th,
.table-very-condensed-matri thead > tr > td,
.table-very-condensed-matri tbody > tr > td,
.table-very-condensed-matri tfoot > tr > td {
  padding: 1px;
}

.ajax-loader-pagecenter {
	display: none;
    width:32px;
    height:32px;
    position:fixed;
    top:50%;
    left:50%;
    margin-top:-16px;
    margin-left:-16px;
}

.form-condensed-matri .form-group {
  margin-top: 0;
  margin-bottom: 5px;
}

.form-condensed-matri input {
  height: 25px;
  font-size: 11px;
  padding-top: 3px;
  padding-bottom: 3px;
  line-height: 1;
}

.form-condensed-matri .control-label {
  font-size: 11px;
  padding-top: 5px;
  padding-left: 7px;
  padding-right: 7px;
}


/* solution suggested at https://github.com/twbs/bootstrap/issues/3737 */
.form-condensed .form-group {
    margin-top: 0;
    margin-bottom: 5px;
}

/* Sampo added to make inputs on form-condensed look like input-sm */
.form-condensed input {
  height: 30px;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  line-height: 1.5;
}

.form-condensed select {
  height: 30px;
  font-size: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  line-height: 1.5;
}

.logo-legacy {
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    max-width: 100%;
}

.clickable {
	cursor: pointer;
}

.modal-dialog-fullsize {
    width: 100%; /* responsive width */
    /* margin-left:-50%; */ /* width/2 -- this should NOT be used with bootstrap 3.0.2 anymore */
}

.modal-dialog-large {
    width: 80%; /* responsive width */
    margin-left: 10%; /* some instructions say width/2, but with BS 3.0.2+ it seems we need to use use 100%-width / 2 to properly offset */
}

.modal-dialog-850px {
    width: 850px; /* responsive width */
    margin-left:-425px; /* width/2 - should probably NOT be used with 3.0.2 anymore, but we dont use the style at all */
}

/* form-horizontal-c is an attempt to create condensed, horizontal form
   used with subsidy applications, at least. */

.form-horizontal-c .form-group {
    margin-top: 0;
    margin-bottom: 4px;
}

.form-horizontal-c .control-label,
.form-horizontal-c .radio,
.form-horizontal-c .checkbox,
.form-horizontal-c .radio-inline,
.form-horizontal-c .checkbox-inline {
  padding-top: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.form-horizontal-c .radio,
.form-horizontal-c .checkbox {
  min-height: 21px;
}

.form-horizontal-c .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

.form-horizontal-c .form-control-static {
  padding-top: 4px;
}

/* ngCloak as instructed by http://docs.angularjs.org/api/ng.directive:ngCloak */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/* table with tr contents vertically aligned */
.m-table-content-vmiddle > tbody > tr > td,
.m-table-content-vmiddle > thead > tr > th {
  vertical-align: middle;
}


/* http://stackoverflow.com/questions/20755094/how-to-reduce-height-between-components-on-bootstrap-3-mvc-5 */
/*vertical height between form-groups*/
.form-packed .form-group {
    margin-bottom: 4px
}
@media (min-width:768px) {
    .form-packed .row {
        margin-left: -1px;
        margin-right: -1px;
    }
    .form-packed [class*="col-"] {
        padding: 0 2px
    }
}

/* http://stackoverflow.com/a/17736031 */
.nav-pills.nav-stacked.nav-condensed > li > a {
   padding-top: 2px;
   padding-bottom: 2px;
} /*original padding value 8px*/

.nav-pills.nav-condensed > li > a {
   padding-top: 2px;
   padding-bottom: 2px;
} /*original padding value 8px*/


/* kasittely tyylit alkaa */
.kasittely-otsikko-tr > td {
   background-color: #0066CC;
   color: white;
}
/* kasittely tyylit loppuu */



/* input group addon pienella paddingilla (esim. taulukoihin) */
.m-input-group-addon-tight {
    padding: 3px;
}

/*
 * Row with equal height columns
 * --------------------------------------------------
 * http://getbootstrap.com.vn/examples/equal-height-columns/equal-height-columns.css
 */
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

/* https://stackoverflow.com/questions/20547819/vertical-align-with-bootstrap-3/25517025#25517025 */
.vertical-align-center {
    display: flex;
    align-items: center;
}

/* a 'disabled' background to be used where real disabled attribute is not usable */
.bg-disabled {
  background-color: #eee;
}


/*
 * drag-and-drop-lists
 */

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
.dndDraggingSource {
  display: none;
}

/* our custom addition to make the handle display drag cursor */
.dnd-drag-handle {
  cursor: move;
}

.mgmt-singleapp-header-row {
  min-height: 30px;
}

.mgmt-singleapp-data-row {
  border-style: solid;
  border-width: thin;
}

.mgmt-singleapp-control {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mgmt-singleapp-p-modification-notification {
  margin-top: 4px;
}
