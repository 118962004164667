/* This file contains Matriset style specifications for OpenLayers3 */
.layer-switcher {
    background-color: transparent;
}
.layer-switcher button {
    float: right;
    width: 20px;
    height: 20px;
    background-image: none /*logo.png*/;
    background-repeat: no-repeat;
    background-position: 2px;
    background-color: #003c88;
    border: none;
}
.layer-switcher button:focus, .layer-switcher button:hover {
    background-color: #003c88;
}
.ol-mouse-position{top:auto;bottom:8px;right:8px;position:absolute;}
.ol-own-control{top:.5em;right:.5em; display:flex;}
.ol-own-control .selected{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Point:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Point{height: 36px;width: 36px;padding:2px;}
.ol-own-control button.LineString:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.LineString{height: 36px;width: 36px;padding:2px;}
.ol-own-control button.LineString[aria-expanded="false"] ul{display:none;}
.ol-own-control button.LineString[aria-expanded="true"] ul{display:inline;background-color:#95b9e6; min-width:40px;float:center;}
.ol-own-control button.LineString[aria-expanded="true"] ul.LineString li{display:inline-block;padding:0.1em 0.5em;float:left;}
.ol-own-control button.LineString[aria-expanded="true"] ul.LineString li button.line32{height:34px;width:34px;}
.ol-own-control button.LineString[aria-expanded="true"] ul.LineString li button.peruskorj32{height:34px;width:34px;}
.ol-own-control button.Polygon:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Polygon{height: 36px;width: 36px;padding:2px;}
.btn-group.marker.open{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Marker{height: 36px;width: 36px;padding: 2px;}
.ol-own-control button.Marker:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Marker[aria-expanded="true"] ul{display:inline-block;background-color:#95b9e6;min-width:140px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li{display:inline-flex;padding:0.1em 0.5em;float:left;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li:nth-child(1){padding:4px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li:nth-child(2){padding-top:4px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li:nth-child(5){padding-top:4px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li button.bridge24{height:26px;width:26px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li button.bridge32{height:34px;width:34px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li button.culvert24{height:26px;width:26px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li button.culvert32{height:34px;width:34px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li button.home32{height:34px;width:34px;}
.ol-own-control button.Marker[aria-expanded="true"] ul.Marker li button.cottage32{height:34px;width:34px;}
.ol-own-control button.Select:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Select{height: 36px;width: 36px;padding:2px;}
.ol-own-control button.Modify:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Modify{height: 36px;width: 36px;padding:2px;}
.ol-own-control button.Save:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Save{height: 36px;width: 36px;padding: 2px;}
.ol-own-control button.Load:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Load{height: 36px;width: 36px;padding: 2px;}
.ol-own-control button.Delete:focus{background-color:#f6f609;border:2px #f6f609;border-radius:2px;}
.ol-own-control button.Delete{height: 36px;width: 36px;padding:2px;background-repeat:no-repeat;background-position: center;}
.ol-full-screen{right:.5em;top:5.5em}
.ol-attribution{bottom:2.5em}
.ol-attribution button{display:none}
.ol-attribution.ol-collapsed ul{display:inline-block}
.ol-zoomslider{position:absolute;}
.ol-overviewmap{position:absolute;}
