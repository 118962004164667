/* HEADER */
span.bullet {
	opacity: 0.35;
	margin-left: 4px;
	margin-right: 4px;
}

.hallintaportaali .logo {
	float: left;
	padding-top:10px;
	padding-bottom: 10px;
	background: #FFF;
}
.hallintaportaali .logo img {
	max-height: 30px;
	margin-right: 10px;
}
.header.hallintaportaali h3 {
	color: #555;
	margin: 0;
	padding: 10px 0;
	line-height: 30px;
	font-size: 18px;
}
.header.hallintaportaali h3 .tr-title {
	font-weight: bold;
	color: 666;
}
.header.hallintaportaali .logout {
	margin: 0;
	padding: 5px 0;
	font-size: 14px;
	line-height: 30px;
	border-top: 1px solid #CCC;
}
.header.hallintaportaali .logout a,
.header.hallintaportaali .logout button {
	font-weight: bold;
}

/* NAV */
#main-navbar {
	padding: 0 1px;
	border-top: 1px solid #23527c;
}
.navbar-nav {
	margin: 0;
}
.navbar.matriset {
	min-height: 40px;
	background: #669911;
	background: linear-gradient(#669911, #448800);
	border: 1px solid #337700;
	margin-bottom: 15px;
	border-radius: 0;
	border-width: 1px 0;
}
.matriset .navbar-brand {
	height: 40px;
	padding: 10px;
	text-shadow: 0 1px 1px rgba(0,0,0,0.4);
	color: #FFF;
	font-size: 14px;
	font-weight: bold;
	margin-left: 0 !important;
}
.navbar.matriset .navbar-nav>li>a {
	padding: 10px;
	color: #ddeeff;
	text-shadow: 0 1px 1px rgba(0,0,0,0.4);
	border-top: 1px dashed rgba(255,255,255,0.4);
	font-size: 13px;
}
.matriset .navbar-brand:hover ,
.navbar.matriset .navbar-nav>li>a:hover {
	color: #FFF;
	background: #337700;
}
.navbar.matriset .navbar-nav>li.active>a {
	background: #FFF;
	color: #333;
	text-shadow: none;
	border-top-color: transparent;
}
.navbar.matriset .navbar-nav>li.active + li>a {
	border-top: transparent;
}
.navbar.matriset .navbar-toggle {
	background: #FFF;
    margin-right: 1px;
    margin-top: 0;
    margin-bottom: 0;
    height: 40px;
    border-radius: 0;
    border: 0;
}
.navbar.matriset .navbar-nav>li.open>a,
.navbar.matriset .navbar-nav>li.open>a:focus,
.navbar.matriset .navbar-nav>li.open>a:hover {
	color: #333;
	background: #e7e7e7;
	text-shadow: none;
}
.navbar-nav>li>.dropdown-menu {
	margin-top: 1px;
	background: #FFF !important;
}

/* TABLE */
.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
	padding: 3px 5px;
}
.table>thead:first-child>tr:first-child>th {
	background: #EEE;
	border-bottom: 0;
}

/* NAV-PILLS */
.tr-theme.nav-pills {
	background: #eee;
	border: 1px solid #CCC;
	margin-bottom: 10px;
}
.tr-theme.nav-pills .navbar-text {
	color: #555;
	background: #CCC;
	padding: 10px;
	margin: 0;
}
.tr-theme.nav-pills>li {
	float: none;
	border-top: 1px solid #CCC;
}
.tr-theme.nav-pills>li+li {
	margin: 0;
}
.tr-theme.nav-pills>li>a {
	border-radius: 0;
}
/* SA: ei otettu k�ytt��n koska active stylen kanssa menee kaikki valkoiseksi
.tr-theme.nav-pills>li>a:hover {
	background: #FFF;	
}
*/
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {

	.header.hallintaportaali .logout {
		padding: 10px 0;
		border: 0;
		text-align: right;
	}

	.tr-theme.nav-pills>li {
		float: left;
		border-top: 0;
		border-right: 1px solid #CCC;
	}

	/* NAV */
	.navbar.matriset .container-fluid {
		padding: 0;
	}
	#main-navbar {
		padding-left: 15px;
		padding-right: 15px;
		border: 0;
	}
	.matriset .navbar-brand {
		margin-left: 0 !important;
	}
	.navbar.matriset .navbar-nav>li.active>a {
		box-shadow: 0 6px 0 #FFF,0 -3px 0 #FFF;
	}
	.navbar.matriset .navbar-nav>li>a {
		border-top: 0;
	}


}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.navbar.matriset .navbar-nav>li>a {
		font-size: 14px;
	}
	.matriset .navbar-brand {
		font-size: 16px;
	}
}